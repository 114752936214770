:root {
  --primary: #0fbb52;
  --secondary: #ff4848;
}

body {
  overflow-x: hidden;
}

.sidebarr {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: white;
  max-width: 300px;
  width: 100%;
  height: 100vh;
  box-shadow: 0px 0px 8px 0px #00000014;
  margin-top: 64.6px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.navbar-brand {
  max-width: 140px;
}

.dropdown img {
  max-width: 40px;
}

.navbar {
  background-color: white;
  box-shadow: 0px 0px 8px 0px #00000014;
}

.main-wrapper {
  margin-left: 300px;
  margin-top: 64.6px;
}

nav ul li a img {
  max-width: 24px !important;
  width: 100%;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.main-heading {
  background: linear-gradient(96deg, #204fa7 3.77%, #ff4343 75.47%);
  border-radius: 0px 0px 20px 20px;
}

.inset-outer-shadow {
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%) inset,
    0px 4px 4px 0px rgb(0 0 0 / 25%) !important;
  padding: 15px;
  border-radius: 12px;
}

.inset-outer-shadows {
  box-shadow: 0px 4px 4px 0px rgba(92, 89, 89, 0.15) inset,
    0px 4px 4px 0px rgb(0 0 0 / 15%) !important;
  padding: 15px;
  border-radius: 12px;
}

.cards .card-body {
  padding: 15px 20px;
  border-radius: 12px;
}

.card-body .card-icon {
  background: linear-gradient(96deg, #204fa7 3.77%, #ff4343 75.47%);
  width: 54px;
  height: 54px;
  border-radius: 8px;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
}

.card-body .card-content h5 {
  font-weight: 700;
  font-size: 28px;
}

.card-body .card-content p {
  font-size: 16px;
}

.mt-n-90 {
  margin-top: -70px;
}

.btn.show,
.btn:first-child:active {
  border-color: white;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  right: 0px;
  left: unset;
}

#sidebar ul li a img {
  filter: brightness(0) invert(0);
}

#sidebar ul li a {
  padding: 10px 15px;
  display: block;
  color: black;
  text-decoration: none;
}

#sidebar ul li {
  margin: 10px 0px;
}

.card-icon img {
  max-height: 24px;
  filter: brightness(0) invert(1);
}

h1 span {
  font-size: 16px;
}

.toggle-btn {
  max-width: 32px;
}

.select__control {
  border-radius: 40px !important;
  min-width: 200px;
}

.search-bar .search-box {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 40px;
  max-width: 576px;
  min-width: 260px;
  width: 100%;
}

.selectedButton,
.selectedButton:active,
.selectedButton:focus {
  background: linear-gradient(96deg, #204fa7 3.77%, #ff4343 75.47%) !important;
  color: #fff !important;
  border: 0px solid !important;
}

.selectedButton:hover,
.selectedButton:focus,
.selectedButton:active {
  color: #fff;
  box-shadow: 0px 0px 0px 0px !important;
  border: 0px solid;
}

.occupant-detail-graph canvas {
  width: 100% !important;
  height: 100% !important;
}

.basic-single input {
  padding: 3px 0px !important;
}

.chart-box button {
  border: 1px solid !important;
  min-width: 80px;
}

.chart-box button:hover {
  box-shadow: 0px 2px 4px 0px #00000059;
}

.progress-bar {
  background-color: #0fbb52 !important;
}

.tab-pane .progress-bar {
  background-color: #ff4343 !important;
}

.nav-tabs .nav-link {
  min-width: 100px;
  border: 0px solid !important;
  color: gray !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: #ff4343 !important;
  border-radius: 8px !important;
}

.men-item {
  background: linear-gradient(99.02deg, #00ce50 1.43%, #00a23f 100%);
  border-radius: 16px;
  padding: 12px;
  max-width: 180px;
  width: 100%;
}

.women-item {
  background: linear-gradient(99.45deg, #ff4343 3.19%, #ff6666 100%);
  border-radius: 16px;
  padding: 12px;
  max-width: 180px;
  width: 100%;
}

.men-item img {
  max-width: 20px;
}

.women-item img {
  max-width: 20px;
}

@media screen and (max-width: 1400px) {
  .card-body p {
    font-size: 14px;
  }

  .card-body .card-icon {
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 1200px) {
  .sidebarr {
    left: -310px;
  }

  .main-wrapper {
    margin-left: 0px;
  }

  .sidebarr.open-sidebar {
    left: 0px;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 576px) {
  .chart-box button {
    min-width: 100%;
  }

  .select__control {
    min-width: 300px;
  }

  .search-bar .search-box {
    max-width: 300px;
  }

  .tabs .nav {
    width: 296px;
  }

  .tabs .nav .nav-link {
    font-size: 14px;
    min-width: 100% !important;
  }

  .tabs .nav {
    width: 300px !important;
  }
}
.blog-post.Vertical .first-img {
  width: 50%;
  height: 300px;
}
.delete-btn,
.blog-post .approve-btn {
  z-index: 9;
}
.blog-post.Text .first-img,
.blog-post.Text .second-img {
  width: 50%;
  height: 300px;
}
.blog-post.Text img {
  height: 100%;
  object-fit: cover;
}
.blog-post.Vertical .second-img {
  width: 50%;
  height: 300px;
}

.blog-post.Vertical img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.blog-post.Horizontal .first-img {
  width: 100%;
  height: 150px;
}

.blog-post.Horizontal .second-img {
  width: 100%;
  height: 150px;
}

.blog-post.Horizontal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.blog-post.Single .first-img {
  width: 100%;
  height: 300px;
}

.blog-post.Single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.blog-post .delete-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-post .approve-btn {
  position: absolute;
  left: 16px;
  top: 16px;
  background-color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-post .approve-btn .fa {
  color: #00ce50;
}

.count {
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
}

.yes-count {
  background-color: #004aad;
}

.no-count {
  background-color: #d40e0f;
}
.chart-box .card {
  height: 100%;
}

.countryChart canvas {
  display: unset !important;
}
.view,
.add-interest,
.interest,
.delete {
  background-color: #0049af;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  color: white;
  cursor: pointer;
}
.delete {
  background-color: #f00;
}

.custom-style {
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-size: 20px;
  caret-color: grey;
}
