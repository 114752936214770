.primary-color {
  background-color: var(--primary);
}
.login-container {
  box-shadow: 0px 0px 8px 0px #0000002b;
  border-radius: 16px;
}
input {
  padding: 10px 15px !important;
}
.sign-in-btn button {
  padding: 10px 15px !important;
  border-radius: 4px;
}
.login-container h4 {
  color: var(--primary);
}
.forgotPassword {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}
.login .container .row {
  height: 100vh;
}

.point {
  cursor: pointer;
}
#sidebar .active {
  color: #ffffff !important;
  background: linear-gradient(96deg, #204fa7 3.77%, #ff4343 75.47%);
  border-radius: 12px;
}
#sidebar .active img {
  filter: brightness(0) invert(1);
}
.selectedMenu {
  color: #ffffff !important;
  background-color: var(--primary);
  padding: 8px 12px !important;
  border-radius: 12px;
}

.dashboard-table .table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.table:not(.table-dark) {
  color: inherit;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent;
}
.dashboard-table .table-hover tbody tr {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 10%);
}
.dashboard-table .table td:first-child {
  border-left: 2px solid #00000008 !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* display: flex; */
}
/* .dashboard-table td {
  white-space: nowrap;
} */
.send-credentials {
  background-color: #e83e8c !important;
  color: white !important;
}
.dashboard-table .table td:first-child img {
  max-width: 40px;
  padding-top: 5px;
}
.dashboard-table .table td h3 {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
}
.new-graph h3,
.overview {
  font-size: 15px;
  color: #535252;
  font-weight: 500;
}
.dashboard-table .table td h5 {
  font-size: 13px;
  padding-top: 5px;
  color: #b2b2b2;
  font-weight: 400;
}
.tabs .nav {
  box-shadow: 0px 0px 4px 0px #00000036;
  background-color: white;
  margin: auto;
  display: inline-flex;
  width: 321px;
  padding: 4px;
}
.tabs .nav .nav-link {
  color: #000000;
  min-width: 156px;
  text-align: center;
}
.tabs .nav .active {
  background-color: #dedede;
}
.content select {
  min-width: 160px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid darkgrey;
}
.first-img-name,
.second-img-name {
  position: absolute;
  z-index: 1;
  color: white;
  top: 50%;
  transform: translate(0px, -50%);
  text-align: center;
  display: block;
  width: 100%;
  padding: 0px 20px;
  background-color: #00000021;
  line-break: anywhere;
  backdrop-filter: blur(4px);
}
.first-img,
.second-img {
  position: relative;
}

.card-custom-height {
  height: 280px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.react-datepicker {
  position: absolute !important;
  right: 0;
}

.third-place {
  padding: 20px;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 260px;
  height: 210px;
  text-align: center;
  box-shadow: inset 0 6px 8px rgba(0, 0, 0, 0.3);
}

.second-place {
  padding: 20px;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 260px;
  height: 210px;
  text-align: center;
  box-shadow: inset 0 6px 8px rgba(0, 0, 0, 0.3);
}

.first-place {
  padding: 20px;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 290px;
  height: 240px;
  text-align: center;
  box-shadow: inset 0 6px 8px rgba(0, 0, 0, 0.3);
}

.stageContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
}

.rank {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
